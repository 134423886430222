/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
html {
  font-family: system-ui, sans-serif;
  --color-bg-page: #fff;
  --color-fg-page: #000;
  --color-bg-letter: #fafab5;
  --color-fg-letter: #2b2611;
  --color-bg-action: #d5f2bb;
  --color-fg-action: #162312;
  --color-bg-problem: #fac8c3;
  --color-fg-problem: #2a1215;
  --color-bg-locked: #d2e0fa;
  --color-fg-locked: #131629;
  --color-bg-winner: #d5f2bb;
  --color-fg-winner: #162312;
  --pool-gap-width: calc(0.25 * var(--letter-width, 48px));
  background: var(--color-bg-page);
  color: var(--color-fg-page);
  font-size: min(4vw, 36px);
}
* {
  box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
  html {
    --color-fg-page: #ddd;
    --color-bg-page: #000;
    --color-fg-letter: #fafab5;
    --color-bg-letter: #2b2611;
    --color-fg-action: #d5f2bb;
    --color-bg-action: #162312;
    --color-fg-problem: #fac8c3;
    --color-bg-problem: #2a1215;
    --color-fg-locked: #d2e0fa;
    --color-bg-locked: #131629;
    --color-fg-winner: #d5f2bb;
    --color-bg-winner: #162312;
  }
}
html, body {
  margin: 0;
  padding: 0;
}
#game {
  --letter-width: 3rem;
}
#pool, #controls, #statistics {
  --letter-width: 3rem;
}
#pool {
  font-size: calc(var(--letter-width) / 2);
  display: grid;
  grid-template-columns: repeat(6, var(--letter-width));
  justify-content: center;
  gap: var(--pool-gap-width);
  list-style: none;
  margin: var(--pool-gap-width) 0 0;
  padding: 0;
}
#pool li, #grid td[data-status] {
  margin: 0;
  padding: 0;
  border: 1px solid black;
  border-radius: 5px;
  width: var(--letter-width);
  height: var(--letter-width);
  text-align: center;
  line-height: var(--letter-width);
  background: var(--color-bg-letter);
  color: var(--color-fg-letter);
}
#game:not([data-victory]) #pool li[data-status="used"] {
  color: transparent;
  opacity: 0.25;
}
#game[data-victory="true"] #pool li {
  background: var(--color-bg-winner);
  color: var(--color-fg-winner);
}
#game[data-victory="false"] #pool li {
  background: var(--color-bg-problem);
  color: var(--color-fg-problem);
}
#game[data-victory="false"] #pool li[data-status="used"] {
  background: var(--color-bg-letter);
  color: var(--color-fg-letter);
}
@keyframes pulsing {
  0% {
    transform: scale(1.0);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  67% {
    transform: scale(1.1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
  }
  100% {
    transform: scale(1.0);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
}
#pool li[data-status="placing"] {
  border-color: transparent;
  transform: scale(1.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  animation: pulsing 1.0s infinite;
}
#grid {
  display: grid;
  font-size: calc(var(--letter-width) / 2);
  gap: calc(0.05 * var(--letter-width));
  justify-content: center;
  grid-template-columns: repeat(var(--column-count, 1), var(--letter-width));
}
#grid tbody, #grid tr {
  display: contents;
}
#grid td {
  display: block;
}
#game[data-victory] #grid td[data-status="placed"]:not([data-problem]) {
  background: var(--color-bg-locked);
  color: var(--color-fg-locked);
}
#game[data-victory] #grid tr:first-of-type,
#game[data-victory] #grid tr:last-of-type {
  display: none;
}
#grid td[data-status="eligible"] {
  background: var(--color-bg-action);
  color: var(--color-fg-action);
}
#game:not([data-placing]) #grid [data-status="eligible"] {
  color: transparent;
  background: transparent;
  border-color: transparent;
}
#game #grid td[data-problem] {
  background: var(--color-bg-problem);
  color: var(--color-fg-problem);
}
#controls {
  text-align: center;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, calc(2 * var(--letter-width) + var(--pool-gap-width)));
  gap: var(--pool-gap-width);
  margin: var(--pool-gap-width) 0;
  padding: 0;
}
#controls button, #close-instructions {
  font-size: calc(var(--letter-width) / 3);
  background: var(--color-bg-page);
  color: var(--color-fg-page);
  border: 1px solid var(--color-fg-page);
  border-radius: 5px;
  padding: 0.5em 0;
  display: block;
}
#controls button[disabled] {
  background: var(--color-bg-page);
  color: var(--color-fg-page);
  opacity: 0.33;
}
#controls button#giveup {
  background: var(--color-bg-problem);
  color: var(--color-fg-problem);
  border-color: var(--color-fg-problem);
}
#game:not([data-victory]) #controls button#share {
  display: none;
}
#game[data-victory] #controls button#giveup {
  display: none;
}
#game[data-victory] #controls button#share, #close-instructions {
  animation: pulsing 1.0s 3 forwards;
  background: var(--color-bg-action);
  color: var(--color-fg-action);
  border-color: var(--color-fg-action);
}

#pool li,
#grid td,
#game[data-placing] #grid td:not([data-status])
{
  cursor: default;
}
#game:not([data-victory]) #pool li:not([data-status]),
#game:not([data-victory]) #pool li[data-status="placing"],
#game[data-placing]:not([data-victory]) #grid td[data-status="eligible"],
#game:not([data-placing]):not([data-victory]) #grid td[data-status="placed"],
#controls button:not([disabled])
{
  cursor: pointer;
}

header {
  line-height: 48px;
  border-bottom: 1px solid #888;
  font-family: serif;
  display: grid;
  grid-template-columns: 48px 1fr 48px;
}
header h1 {
  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 2;
  margin: 0;
  grid-column: 2 / span 1;
}
header #open-instructions {
  border: 1px solid #888;
  border-radius: 50%;
  display: block;
  float: right;
  width: 36px;
  height: 36px;
  font-size: 24px;
  margin: 6px;
  grid-column: 3 / span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#result {
  position: absolute;
  left: -99999px;
  width: 1px;
  height: 1px;
}
#copied, #instructions {
  display: none;
  position: fixed;
  border-radius: 5px;
  padding: 0.5em 1em;
  border: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: 16px;
}
#copied.visible, #instructions.visible {
  display: block;
}
#copied {
  top: 75%;
  left: 25%;
  right: 25%;
  text-align: center;
  background: var(--color-fg-page);
  color: var(--color-bg-page);
}
#instructions {
  top: 5vh;
  bottom: 5vh;
  width: 20rem;
  left: calc(50% - 10rem);
  overflow-y: auto;
  z-index: 3;
  background: var(--color-bg-page);
  color: var(--color-fg-page);
}
@keyframes toastup {
  0% { top: 80%; opacity: 0; }
  100% { top: 75%; opacity: 1; }
}
#copied.visible {
  animation: toastup 0.33s ease-in-out;
}
@keyframes modaldown {
  0% { top: 0vh; opacity: 0; }
  100% { top: 5vh; opacity: 1; }
}
#instructions.visible {
  animation: modaldown 0.33s ease-in-out;
}
#instructions.visible + #modal-bg {
  position: fixed;
  background: black;
  opacity: 0.33;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
#close-instructions {
  font-size: inherit;
  width: 80%;
  margin: 2em auto 0;
  cursor: pointer;
}

#game:not([data-victory]) #statistics {
  display: none;
}
#statistics {
  /* border: 1px solid #888; */
  margin: var(--pool-gap-width) auto;
  padding: var(--pool-gap-width) 0;
  width: calc((6 * var(--letter-width)) + (5 * var(--pool-gap-width)));
  display: flex;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--pool-gap-width);
  row-gap: calc(2 * var(--pool-gap-width));
  font-size: 18px;
  font-family: serif;
  text-align: center;
}
#countdown {
  font-size: 24px;
  grid-column: 1 / span 4;
}
@media (min-width: 768px) {
  #statistics {
    grid-template-columns: repeat(6, 1fr);
  }
  #countdown {
    font-size: 18px;
    grid-column: 1 / span 2;
  }
}
#countdown-time, #streaks li span {
  display: block;
  font-size: 5.2ch;
  font-family: sans-serif;
}
#streaks {
  display: contents;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: pre-line;
}
#streaks li {
  text-transform: capitalize;
}